@import "../../../../styles/styles.scss";

.success-message {
  margin-top: 0.3rem;
  text-align: center;
  font-size: $medium;
  color: $accent-color;
}

@include media-s {
  .recaptcha-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
