@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.slider {
  overflow: hidden;
  padding: 40px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.slide {
  display: inline-block;
  animation: 35s slide infinite linear;
}
