@import "../../../styles/styles.scss";

.services {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.2rem;

  h3 {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }
}

.service {
  width: 50%;
}

.service-logo {
  width: 3.2rem;
  fill: $secondary-color-light-p;
  margin-bottom: 1.2rem;
}

@include media-m {
  .service {
    width: 100%;
  }
}
