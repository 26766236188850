@import "../../styles/styles.scss";

.resume {
  width: 66%;
  padding: 1rem 6rem 2rem;
}

.tech-logo {
  height: 50px;
  margin: 0 40px;
}

.client-logo {
  height: 100px;
}

.link-logo {
  display: inherit;
  margin: 0 40px;
}

.stackshare {
  color: $secondary-color-light-p;
  font-size: $small;
  text-decoration: none;
  font-weight: bold;
  img {
    margin-left: 1rem;
    height: 2rem;
  }

  &:hover {
    color: $primary-color;
  }
}

@include media-m {
  .resume {
    width: 90%;
    margin: auto;
    padding: 0;
  }
}
