@import "../../../styles/styles.scss";

.cards {
  display: flex;
  flex-wrap: wrap;
}

@include media-s  {
  .cards {
    flex-direction: column;
  }
}
