@import "../../styles/styles.scss";

.Top {
  background: linear-gradient(145deg, rgb(221, 224, 228), rgb(113, 127, 150));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h1 {
    font-family: $heading-font;
    font-size: 4vh;
    font-weight: 300;
    margin: 0px;
    letter-spacing: 0.5vh;
    text-transform: uppercase;
  }
  h2 {
    color: $primary-color;
    font-family: $heading-font;
    font-size: 2vh;
    font-weight: 300;
    margin-bottom: 1vh;
    letter-spacing: 0.5vh;
    text-transform: uppercase;
  }

  .svg-logo {
    width: 8vh;
    stroke: white;
    fill: white;
  }
}

@include media-m {
  h1 {
    text-align: center;
  }
}
