@import "..//../styles/styles.scss";

.profile {
  width: 33%;
  background: $secondary-color-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8rem 6rem 2rem;

  h1 {
    line-height: 3rem;
    text-transform: uppercase;
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  .avatar-img {
    margin-bottom: 33px;
    border-radius: 50%;
    width: 195px;
    height: 195px;
  }

  .social-icons {
    margin-top: 1.5rem;
    font-size: 1.4rem;
    bottom: 30px;
    padding: 0;
  }

  .social-icons li {
    display: inline-block;
    margin-right: 20px;
  }

  .social-icons li:last-child {
    margin-right: 0;
  }

  .social-icons li a {
    color: $primary-color;
    @include transition-ease;
  }

  .social-icons li a:hover {
    color: $accent-color;
  }
}

@include media-m {
  .profile {
    width: 100%;
    padding: 6rem 0 2rem;
  }
}
