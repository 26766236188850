@import "../../../styles/styles.scss";

.contacts {
  display: flex;
}

.contacts-form {
  width: 55%;
}

.contact {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contacts-icon {
  color: $secondary-color-light-p;
  height: 2rem;
}

.icon-small {
  height: 1.5rem;
}

.copy-clipboard:hover {
  cursor: pointer;
}

.clipboard-msg {
  position: absolute;
  top: 0;
  background-color: #eee;
  padding: 0.2rem;
  border-radius: 10px;
  font-size: $small;
  color: $primary-color;
}

.contacts-data {
  padding: 2rem;
  position: relative;

  a {
    text-decoration: none;
  }
}

@include media-m {
  .contacts {
    display: block;
  }

  .contacts-form {
    width: 100%;
  }

  .contacts-data {
    padding: 0;
    margin-top: 2rem;
    text-align: center;
  }

  .contact {
    flex-direction: column;
    gap: 0;
  }

  .clipboard-msg {
    position: initial;
    top: initial;
  }
}

@include media-s {
  .email {
    font-size: 1rem;
  }
}