@import "../../../styles/styles.scss";

.about {
  text-transform: uppercase;
  color: $accent-color;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  margin-top: 5rem;
}

@include media-m {
  .about {
    margin-top: 3rem;
  }
}
