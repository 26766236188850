@import "../../styles/styles.scss";

.main {
  display: flex;
  overflow-x: hidden;
}

@include media-m {
  .main {
    display: block;
  }
}
