@import "../../../styles/styles.scss";

.timeline {
  position: relative;
}

.timeline:before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 18px;
  position: absolute;
  top: 50px;
  width: 1px;
  height: 90%;
}

.exp {
  padding: 20px 20px 0 60px;
  position: relative;

  a {
    color: $accent-color;
  }
}

.exp:after {
  background: #333;
  border: 2px solid #333;
  border-radius: 50%;
  content: "";
  height: 5px;
  width: 5px;
  left: 16px;
  position: absolute;
  text-align: center;
  top: 32px;
}

.exp-holder {
  margin-top: 70px;
}

.exp .hgroup h4,
.exp .hgroup h5 {
  display: inline-block;
  text-transform: none;
  margin-bottom: 10px;
  font-weight: 700;
}

.exp .hgroup {
  margin-bottom: 5px;
}

.badges {
  display: inline-block;
}

.badge {
  margin-left: 1rem;
  min-width: 10px;
  padding: 3px 7px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

@include media-m {
  .badge {
    margin-left: 1rem;
  }
}

@include media-s {
  .badge {
    margin-left: 0;
  }
}
