@import "../../../../styles/styles.scss";

.project-card {
  width: 40%;
  height: auto;
  border-radius: 18px;
  border: none;
  background: linear-gradient(145deg, rgb(221, 224, 228), rgb(113, 127, 150));
  color: $primary-color;
  margin: 1rem;
  padding: 0.3rem;
  position: relative;
  box-shadow: 11px 10px 30px rgba(113, 127, 150, 0.8);
  -webkit-box-shadow: 11px 10px 30px rgba(113, 127, 150, 0.8);
  -moz-box-shadow: 11px 10px 30px rgba(113, 127, 150, 0.8);

  &:hover {
    box-shadow: 6px 10px 28px -1px rgba(113, 127, 150, 0.7);
    -webkit-box-shadow: 6px 10px 28px -1px rgba(113, 127, 150, 0.7);
    -moz-box-shadow: 6px 10px 28px -1px rgba(113, 127, 150, 0.7);
  }
}

.project-card-wrapper {
  text-decoration: none;
  border-radius: 18px;
  display: block;
  width: 100%;
  height: 100%;
  background: $background-color;
  padding: 1rem;
}

.project-card-logo {
  position: relative;
  width: 10rem;
  margin: 0.2rem auto;
}

.project-card-logo-png {
  width: 100%;
}

.project-card-title {
  font-weight: bold;
  margin-top: 1rem;
}

.project-card-description {
  text-align: justify;
}

@include media-m {
  .project-card {
    width: 60%;
  }
}

@include media-s {
  .project-card {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
    height: auto;
  }
}
